import React from "react"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Form from "../components/Form"
import Footer from "../components/Footer"
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ServicesWrapper = styled.section`
  section {
    padding: 80px 0;
    &:nth-child(2n + 2) {
      background-color: #1c1c1c;
    }
    &:nth-child(2n + 1) {
      background-color: #141414;
    }
    .gatsby-image-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      color: #fff;
      font-size: 24px;
    }
    p {
      span {
        color: #bd9d3f;;
      }
    }
  }
`

const BannerContent = {
  title: "Services",
  description:
    "I provide various services in different platform along with the channel of combining <span>group of expertise</span> in a large scale project upon requirement.",
}

const Works = () => {

   const data = useStaticQuery(graphql`
     query {
       image1: file(relativePath: { eq: "service-graphics.png" }) {
         ...servicesLists
       }

       image2: file(relativePath: { eq: "service-psd-html.png" }) {
         ...servicesLists
       }

       image3: file(relativePath: { eq: "service-web-development.png" }) {
         ...servicesLists
       }

       image4: file(relativePath: { eq: "service-connect.png" }) {
         ...servicesLists
       }
     }
   `)

  return (
    <Layout>
      <Banner
        title={BannerContent.title}
        description={BannerContent.description}
      ></Banner>
      <ServicesWrapper>
        <section className="each-s-section">
          <Container>
            <Row className="align-items-center">
              <Col xs={7}>
                <div className="ess-img">
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    alt="Services - Graphics"
                  />
                </div>
              </Col>
              <Col>
                <h2>Graphics Design</h2>
                <p>
                  The Core Foundation of any project in web or mobile starts
                  with the subtle <span>graphic entities</span>. I have solid
                  grasp of how the graphics layer will affect the feel of the
                  public end result and how they need to be. I am also able to
                  cater every possible ideas in terms of drawing a quality
                  picture of an organization's business.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="each-s-section">
          <Container>
            <Row className="align-items-center">
              <Col>
                <h2>PSD to HTML</h2>
                <p>
                  In Web, the conversion of digital idea into reality begins
                  with the conversion of Graphics layer into front end coding. I
                  possess wild compassion of developing{" "}
                  <span>Pixel Perfect</span> front end development. I write
                  efficient and precise code, meet all the standard of both html
                  and css. I take care of how the end result looks in the
                  different browsers and different devices, but also take good
                  care of how the code been written to make that happen.
                </p>
                <p>
                  In addition to that, I am passionate about doing cool
                  <span>animation</span> with different tools and technologies,
                  to have a great feel on the websites, and that can engage
                  traffics a bit more than usual.
                </p>
              </Col>
              <Col xs={7}>
                <div className="ess-img">
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    alt="Services - Graphics"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="each-s-section">
          <Container>
            <Row className="align-items-center">
              <Col xs={7}>
                <div className="ess-img">
                  <Img
                    fluid={data.image3.childImageSharp.fluid}
                    alt="Services - Graphics"
                  />
                </div>
              </Col>
              <Col>
                <h2>Web Development</h2>
                <p>
                  I have got extensive knowledge of backend development, whether
                  it be a Built in CMS like Wordpress or building{" "}
                  <span>bespoke CMS</span> using PHP and its framework.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="each-s-section">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={7}>
                <h2>Connect with Group</h2>
                <p>
                  It's one of those cases, where my client ask me to do
                  different tasks, that I am not experienced in or requires more
                  than one developer for the project, in those cases I help my
                  client to connect with my amazing circle of expertise in those
                  particular task or project. Not only its difficult to find
                  multiple developers, its hard to manage all of the developer
                  who lives in <span>different timezone</span>, which often lead
                  to communication gap, improper project management and pushes
                  the deadline beyond imagination, in those cases I help reduce
                  the time and effort of my clients by grouping and managing a
                  circle of expertise from one location.
                </p>
                <div className="ess-img">
                  <Img
                    fluid={data.image4.childImageSharp.fluid}
                    alt="Services - Graphics"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </ServicesWrapper>
      <Form></Form>
      <Footer></Footer>
    </Layout>
  )
}

export default Works

export const query = graphql`
  fragment servicesLists on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`